import { MDXProvider } from '@mdx-js/react';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { Page, Typography } from '../ui';
import { PageBreadcrumbHeaderProps } from '../ui/components/Page/PageBreadcrumbHeader';
import { GlobalStyle } from '../ui/GlobalStyle';
import { DefaultTheme, Theme } from '../ui/themes/DefaultTheme';
import { useSystemTheme } from './utils/useSystemTheme';

const ThemeProvider = StyledThemeProvider as any;

export interface I18nConfig {
  'en-US': {
    [key: string]: string;
  };
  cs: {
    [key: string]: string;
  };
}

type GetComponentProps<T> = T extends
  | React.ComponentType<infer P>
  | React.Component<infer P>
  ? P
  : never;

export interface DefaultLayoutProps {
  theme?: Theme;
  themeOverride?: Partial<Theme>;
  breadcrumb?: PageBreadcrumbHeaderProps['items'];
  mdxComponents?: GetComponentProps<typeof MDXProvider>['components'];
  title?: string;
  children?: React.ReactNode;
  locale?: keyof I18nConfig;
  messages?: I18nConfig;
  background?: React.ReactNode;
}

const defaultMessages: I18nConfig = {
  'en-US': {},
  cs: {},
};

export const DefaultLayout: React.FC<React.PropsWithChildren<
  DefaultLayoutProps
>> = props => {
  const {
    children,
    breadcrumb,
    theme: staticTheme,
    themeOverride = {},
    mdxComponents = {},
    locale = 'en-US',
    messages = {},
    background,
  } = props;

  const theme = useSystemTheme(DefaultTheme); //, themeOverride);

  React.useEffect(() => {
    const s = document.createElement('script');
    s.setAttribute('src', 'https://platform.twitter.com/widgets.js');
    s.setAttribute('async', 'true');
    document.head.appendChild(s);
  }, []);

  // @ts-ignore
  const localisedMessages = messages[locale as any];

  return (
    <ThemeProvider theme={staticTheme || theme}>
      <IntlProvider locale={locale} messages={localisedMessages}>
        <MDXProvider
          components={{
            h1: props => <Typography.Headline level={1} {...props} />,
            h2: props => <Typography.Headline level={2} {...props} />,
            h3: props => <Typography.Headline level={3} {...props} />,
            h4: props => <Typography.Headline level={4} {...props} />,
            h5: props => <Typography.Headline level={5} {...props} />,
            h6: props => <Typography.Headline level={6} {...props} />,
            p: props => <Typography.Paragraph as="span" {...props} />,
            a: props => <Typography.Link ref={undefined as any} {...props} />,
            pre: props => {
              return null;
            },
            li: props => (
              <li>
                <Typography.Paragraph {...props} />
              </li>
            ),
            ...mdxComponents,
          }}
        >
          <Page>
            <GlobalStyle />

            {background}

            {/*  {breadcrumb && <Page.BreadcrumbHeader items={breadcrumb} />}
             */}

            <Page.Frame breadcrumb={breadcrumb}>{children}</Page.Frame>
          </Page>
        </MDXProvider>
      </IntlProvider>
    </ThemeProvider>
  );
};
